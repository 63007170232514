<script setup lang="ts">
import type { RouteLocationRaw } from "#vue-router"

const props = withDefaults(
  defineProps<{
    content: Course
    deadline?: string
    referrer?: string
    placement?: string
    dark?: boolean
  }>(),
  {
    deadline: undefined,
    referrer: "",
    placement: "",
    dark: false,
  },
)

const course = computed(() => props.content)
const link: RouteLocationRaw = {
  name: "courses-courseId",
  params: { courseId: props.content.id },
  query: { referrer: props.referrer, placement: props.placement },
}

const { data: allCourses } = await useCachedFetch<CourseList>("/courses", {
  method: "post",
  key: "courses",
})
const progress = computed(() =>
  allCourses.value?.progress.find((p) => p.course_id == course.value.id),
)
const currentStep = computed(() => progress.value?.step ?? 0)
const percentageCompleted = computed(() =>
  Math.round(((progress.value?.step ?? 0) / course.value.lesson_count) * 100),
)

const coursePreviouslyCompleted = computed(() => {
  return progress.value?.step == course.value.lesson_count
})

defineEmits(["link-clicked"])
</script>

<template>
  <div class="@container">
    <div class="relative flex flex-col gap-4 @2xl:flex-row">
      <NuxtLink :to="link" @click="$emit('link-clicked')">
        <div
          class="relative aspect-video overflow-hidden rounded-xl bg-slate-200 ring-1 @2xl:h-40"
          :class="dark ? 'ring-slate-800' : 'ring-slate-200'"
        >
          <div v-if="course.video">
            <video
              playsinline
              autoplay
              loop
              muted
              :poster="course.image"
              class="rounded-t-xl hover:opacity-85"
            >
              <source :src="course.video" type="video/mp4" />
            </video>
          </div>
          <img
            v-else
            :src="course.image ?? '/img/placeholder.jpg'"
            class="rounded-t-xl transition-all hover:opacity-85"
          />
          <div
            class="absolute bottom-2 left-2 flex items-center rounded-sm bg-slate-600 px-1.5 py-0.5 text-sm text-white"
          >
            {{ $t("courses.lessons", { count: course.lesson_count }) }}
          </div>
        </div>
      </NuxtLink>
      <div
        class="relative flex grow flex-col justify-between gap-4 @2xl:items-start"
      >
        <NuxtLink :to="link" @click="$emit('link-clicked')">
          <TypographyHeading :level="3" :class="{ 'text-white': dark }">
            {{ course.title }}
          </TypographyHeading>
          <MandatoryDeadlineBadge
            v-if="deadline"
            :deadline
            class="text-slate-400!"
          />
        </NuxtLink>
        <FormButton
          :to="link"
          :variant="dark ? 'dark' : 'secondary'"
          size="sm"
          @click="$emit('link-clicked')"
        >
          {{
            currentStep > 0
              ? $t("courses.progress.button")
              : $t("courses.content.introduction.start")
          }}
        </FormButton>
        <Badge
          v-if="coursePreviouslyCompleted"
          class="absolute top-1 right-1 hidden flex-row items-center justify-start @sm:flex"
        >
          <template #icon>
            <SvgoPikaRefresh />
          </template>
          {{ $t("mandatory.retake") }}
        </Badge>
        <div
          v-else-if="currentStep > 0"
          class="absolute top-1 right-1 hidden flex-row items-center justify-start @sm:flex"
        >
          <div class="rounded-full bg-white p-0.5 ring-2 ring-slate-100">
            <ProgressRing
              :radius="14"
              :progress="percentageCompleted"
              :stroke="3"
            />
          </div>
          <p class="pl-2 text-sm font-medium text-slate-800">
            {{ `${percentageCompleted}%` }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
