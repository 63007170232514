<script setup lang="ts">
import type { RouteLocationRaw } from "#vue-router"

const props = withDefaults(
  defineProps<{
    content: WithRequired<Video, "category">
    deadline?: string
    referrer?: string
    placement?: string
    dark?: boolean
  }>(),
  {
    deadline: undefined,
    referrer: "",
    placement: "",
    dark: false,
  },
)
const video = computed(() => props.content)
const categorySlug = camelToKebab(video.value.category)

const link: RouteLocationRaw = {
  name: "videos-category-video-id",
  params: { category: categorySlug, id: video.value.id },
  query: { referrer: props.referrer, placement: props.placement },
}

const { session } = useAuth()
const videoPreviouslyWatched = computed(() => {
  if (!session.value?.watched_videos) {
    return false
  }
  return session.value.watched_videos.includes(video.value.id)
})

defineEmits(["link-clicked"])
</script>

<template>
  <div class="@container">
    <div class="relative flex flex-col gap-4 @2xl:flex-row">
      <NuxtLink :to="link" @click="$emit('link-clicked')">
        <div
          class="relative aspect-video overflow-hidden rounded-xl bg-slate-200 ring-1 @2xl:h-40"
          :class="dark ? 'ring-slate-800' : 'ring-slate-200'"
        >
          <img
            :src="video.image ?? '/img/placeholder.jpg'"
            class="rounded-t-xl transition-all hover:opacity-85"
          />
          <VideoDuration :duration="video.duration" />
        </div>
      </NuxtLink>
      <div
        class="relative flex grow flex-col justify-between gap-4 @2xl:items-start"
      >
        <NuxtLink :to="link" @click="$emit('link-clicked')">
          <TypographyHeading :level="3" :class="{ 'text-white': dark }">
            {{ video.title }}
          </TypographyHeading>
          <MandatoryDeadlineBadge
            v-if="deadline"
            :deadline
            class="text-slate-400!"
          />
        </NuxtLink>
        <FormButton
          :to="link"
          :variant="dark ? 'dark' : 'secondary'"
          size="sm"
          @click="$emit('link-clicked')"
        >
          {{ $t("videos.play") }}
        </FormButton>
        <Badge
          v-if="videoPreviouslyWatched"
          class="absolute top-1 right-1 hidden flex-row items-center justify-start @sm:flex"
        >
          <template #icon>
            <SvgoPikaRefresh />
          </template>
          {{ $t("mandatory.retake") }}
        </Badge>
      </div>
    </div>
  </div>
</template>
