<script setup lang="ts">
const props = withDefaults(defineProps<{ deadline?: string }>(), {
  deadline: undefined,
})

function isInNearFuture(date: string) {
  const now = new Date()
  const targetDate = new Date(date)

  const timeDifferenceMillis = targetDate.getTime() - now.getTime()

  const dayDifference = timeDifferenceMillis / (1000 * 3600 * 24)

  return dayDifference <= 3 && dayDifference >= 0
}

const formattedDeadline = computed(() =>
  props.deadline ? formatDate(new Date(props.deadline)) : undefined,
)
const showDeadlineAlert = computed(() =>
  props.deadline ? isInNearFuture(props.deadline) : false,
)
</script>

<template>
  <div class="mt-1 flex items-center text-sm text-slate-500 lg:mb-0">
    <div v-if="showDeadlineAlert" class="relative mr-2 flex h-3 w-3">
      <div
        class="absolute inline-flex h-full w-full animate-[ping_2s_linear_infinite] rounded-full bg-red-700 opacity-75"
      />
      <div class="relative inline-flex h-3 w-3 rounded-full bg-red-700" />
    </div>
    {{ $t("mandatory.deadline", { date: formattedDeadline }) }}
  </div>
</template>
