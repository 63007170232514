<script setup lang="ts">
import type { RouteLocationRaw } from "#vue-router"

const props = withDefaults(
  defineProps<{
    content: Policy
    deadline?: string
    dark?: boolean
    referrer?: string
  }>(),
  {
    deadline: undefined,
    dark: false,
    referrer: "",
  },
)

const policy = computed(() => props.content)
const link: RouteLocationRaw = {
  name: "policies-policyId",
  params: { policyId: props.content.id },
  query: { referrer: props.referrer },
}

defineEmits(["link-clicked"])
</script>

<template>
  <div class="@container">
    <div class="relative flex flex-col gap-4 @2xl:flex-row">
      <NuxtLink :to="link" @click="$emit('link-clicked')">
        <div
          class="aspect-video overflow-hidden rounded-xl bg-white bg-conic-120 from-slate-50 from-50% via-yellow-300/10 via-75% to-fuchsia-100/45 to-90% ring-1 backdrop-blur-lg @2xl:h-40"
          :class="dark ? 'ring-slate-800' : 'ring-slate-200'"
        >
          <div class="left-8 ml-4 flex items-center justify-end">
            <svg class="w-full lg:w-72" viewBox="0 0 240 260" fill="none">
              <!-- Bottom document -->
              <g
                transform="translate(105, 110) rotate(-10) translate(-45, -90)"
              >
                <rect
                  x="20"
                  y="30"
                  width="140"
                  height="180"
                  rx="4"
                  fill="white"
                  stroke="#e2e8f0"
                  stroke-width="1"
                  filter="drop-shadow(2px 2px 4px rgba(100, 116, 139, 0.2))"
                />

                <!-- Lines -->
                <rect
                  x="40"
                  y="60"
                  width="100"
                  height="8"
                  rx="2"
                  fill="#e2e8f0"
                />
                <rect
                  x="40"
                  y="75"
                  width="100"
                  height="8"
                  rx="2"
                  fill="#e2e8f0"
                />
                <rect
                  x="40"
                  y="90"
                  width="100"
                  height="8"
                  rx="2"
                  fill="#e2e8f0"
                />
                <rect
                  x="40"
                  y="105"
                  width="60"
                  height="8"
                  rx="2"
                  fill="#e2e8f0"
                />
              </g>

              <!-- Top document -->
              <g transform="translate(120, 120) rotate(5) translate(-35, -120)">
                <rect
                  x="20"
                  y="30"
                  width="140"
                  height="180"
                  rx="4"
                  fill="#ffffff"
                  stroke="#e2e8f0"
                  stroke-width="1"
                  filter="drop-shadow(1px 1px 3px rgba(100, 116, 139, 0.15))"
                />

                <!-- Lines -->
                <rect
                  x="40"
                  y="60"
                  width="100"
                  height="8"
                  rx="2"
                  fill="#e2e8f0"
                />
                <rect
                  x="40"
                  y="75"
                  width="100"
                  height="8"
                  rx="2"
                  fill="#e2e8f0"
                />
                <rect
                  x="40"
                  y="90"
                  width="100"
                  height="8"
                  rx="2"
                  fill="#e2e8f0"
                />
                <rect
                  x="40"
                  y="105"
                  width="60"
                  height="8"
                  rx="2"
                  fill="#e2e8f0"
                />
              </g>
            </svg>
          </div>
        </div>
      </NuxtLink>
      <div
        class="relative flex grow flex-col justify-between gap-4 @2xl:items-start"
      >
        <NuxtLink :to="link" @click="$emit('link-clicked')">
          <TypographyHeading :level="3" :class="{ 'text-white': dark }">
            {{ policy.title }}
          </TypographyHeading>
          <MandatoryDeadlineBadge
            v-if="deadline"
            :deadline
            class="text-slate-400!"
          />
        </NuxtLink>
        <FormButton
          :to="link"
          :variant="dark ? 'dark' : 'secondary'"
          size="sm"
          @click="$emit('link-clicked')"
        >
          {{ $t("mandatory.view_policy") }}
        </FormButton>
      </div>
    </div>
  </div>
</template>
